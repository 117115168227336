
import {
  defineComponent,
  onMounted,
  reactive,
  watch,
  ref,
  nextTick,
  toRefs
} from 'vue'
import { isEmpty } from '@/utils/validate'
import { useRoute, LocationQuery, useRouter } from 'vue-router'
import { useStore } from '@/store'
import { UserActionTypes } from '@/store/modules/user/action-types'
import { verifCodeRequest } from '@/apis/user'
import { useI18n } from 'vue-i18n'
import { ElMessage } from 'element-plus'
import storage, { StorageType } from '@/utils/storage'
export default defineComponent({
  setup() {
    const userNameRef = ref(null)
    const passwordRef = ref(null)
    const loginFormRef = ref(null)
    const router = useRouter()
    const route = useRoute()
    const store = useStore()
    const { t } = useI18n()
    const staticMethods = {
      validateUsername: (rule: any, value: string, callback: Function) => {
        if (!isEmpty(value)) {
          callback(new Error('用户名不能为空'))
        } else {
          callback()
        }
      },
      validatePassword: (rule: any, value: string, callback: Function) => {
        if (!isEmpty(value)) {
          callback(new Error('密码不能为空'))
        } else {
          callback()
        }
      },
      validateAuthcode: (rule: any, value: string, callback: Function) => {
        if (value.length < 4) {
          callback(new Error('验证码不能少于4位数字'))
        } else {
          callback()
        }
      }
    }
    const { validateUsername, validatePassword, validateAuthcode } = staticMethods
    const state = reactive({
      authcodeimgSrc: '',
      uuid: '',
      rememberPass: false,
      loginForm: {
        username: '',
        password: '',
        authcode: ''
      },
      loginRules: {
        username: [{ validator: validateUsername, trigger: 'blur' }],
        password: [{ validator: validatePassword, trigger: 'blur' }],
        authcode: [{ validator: validateAuthcode, trigger: 'blur' }]
      },
      passwordType: 'password',
      loading: false,
      redirect: '',
      otherQuery: {}
    })

    const methods = reactive({
      showPwd: () => {
        if (state.passwordType === 'password') {
          state.passwordType = ''
        } else {
          state.passwordType = 'password'
        }
        nextTick(() => {
          (passwordRef.value as any).focus()
        })
      },
      getCaptcha: async() => {
        await verifCodeRequest({}).then((res) => {
          if (res?.code === 200) {
            state.uuid = res.data.uuid
            state.authcodeimgSrc = res.data.url
          }
        }, (error) => {
          ElMessage.error(error)
        })
      },
      handleLogin: () => {
        (loginFormRef.value as any).validate(async(valid: boolean) => {
          if (valid) {
            state.loading = true
            const { username, password, authcode } = state.loginForm
            if (state.rememberPass) {
              storage.rcSetItem(StorageType.local, 'user', {
                username,
                password
              })
            }
            await store.dispatch(UserActionTypes.ACTION_LOGIN, {
              agent_name: username,
              password,
              captcha: authcode,
              captcha_uuid: state.uuid
            })
            router
              .push({
                path: state.redirect || '/',
                query: state.otherQuery
              })
              .catch(err => {
                console.warn(err)
              })
            state.loading = false
          } else {
            return false
          }
        })
      }
    })

    function getOtherQuery(query: LocationQuery) {
      return Object.keys(query).reduce((acc, cur) => {
        if (cur !== 'redirect') {
          acc[cur] = query[cur]
        }
        return acc
      }, {} as LocationQuery)
    }

    watch(() => route.query, query => {
      if (query) {
        state.redirect = query.redirect?.toString() ?? ''
        state.otherQuery = getOtherQuery(query)
      }
    })

    onMounted(() => {
      methods.getCaptcha()
      if (state.loginForm.username === '') {
        (userNameRef.value as any).focus()
      } else if (state.loginForm.password === '') {
        (passwordRef.value as any).focus()
      }
      // 获取本地记住密码
      const localUsermess = storage.rcGetItem(StorageType.local, 'user')
      if (localUsermess) {
        const usermess = JSON.parse(localUsermess)
        state.loginForm.username = usermess.username
        state.loginForm.password = usermess.password
      }
    })

    return {
      userNameRef,
      passwordRef,
      loginFormRef,
      ...toRefs(state),
      ...toRefs(methods),
      t
    }
  }
})
