<template>
  <div class="login-container">
    <img
      src="../../../assets/images/login/video-cover.jpeg"
      alt="video-cover"
      class="cover login-bg "
    >

    <el-form
      ref="loginFormRef"
      :model="loginForm"
      :rules="loginRules"
      class="login-form"
      autocomplete="on"
      label-position="left"
    >
      <div class="title-container">
        <h3 class="title">
          {{ t("login.title") }}
        </h3>
      </div>

      <el-form-item prop="username">
        <span class="svg-container">
          <i class="el-icon-user" />
        </span>
        <el-input
          ref="userNameRef"
          v-model="loginForm.username"
          :placeholder="t('login.username')"
          name="username"
          type="text"
          tabindex="1"
          autocomplete="on"
        />
      </el-form-item>

      <el-form-item prop="password">
        <span class="svg-container">
          <i class="el-icon-lock" />
        </span>
        <el-input
          :key="passwordType"
          ref="passwordRef"
          v-model="loginForm.password"
          :type="passwordType"
          :placeholder="t('login.password')"
          name="password"
          tabindex="2"
          autocomplete="on"
        />
        <span
          class="show-pwd"
          @click="showPwd"
        >
          <svg-icon
            :name="passwordType === 'password' ? 'eye-off' : 'eye-on'"
          />
        </span>
      </el-form-item>

      <el-form-item prop="authcode">
        <el-input
          ref="authCodeRef"
          v-model="loginForm.authcode"
          placeholder="输入验证码"
          name="authcode"
          type="text"
          tabindex="3"
          autocomplete="off"
          class="authcodeInput"
          @keyup.enter="handleLogin"
        />
        <div class="authcodeimg">
          <img
            :src="authcodeimgSrc"
            alt="code-img"
            @click="getCaptcha"
          >
        </div>
      </el-form-item>
      <el-button
        :loading="loading"
        type="primary"
        style="width:100%; margin-bottom:30px;"
        @click.prevent="handleLogin('loginForm')"
      >
        登录
      </el-button>
      <el-checkbox
        v-model="rememberPass"
        :change="changeRememberPass"
        class="remember-pass"
      >
        记住密码
      </el-checkbox>
    </el-form>
  </div>
</template>

<script lang="ts">
import {
  defineComponent,
  onMounted,
  reactive,
  watch,
  ref,
  nextTick,
  toRefs
} from 'vue'
import { isEmpty } from '@/utils/validate'
import { useRoute, LocationQuery, useRouter } from 'vue-router'
import { useStore } from '@/store'
import { UserActionTypes } from '@/store/modules/user/action-types'
import { verifCodeRequest } from '@/apis/user'
import { useI18n } from 'vue-i18n'
import { ElMessage } from 'element-plus'
import storage, { StorageType } from '@/utils/storage'
export default defineComponent({
  setup() {
    const userNameRef = ref(null)
    const passwordRef = ref(null)
    const loginFormRef = ref(null)
    const router = useRouter()
    const route = useRoute()
    const store = useStore()
    const { t } = useI18n()
    const staticMethods = {
      validateUsername: (rule: any, value: string, callback: Function) => {
        if (!isEmpty(value)) {
          callback(new Error('用户名不能为空'))
        } else {
          callback()
        }
      },
      validatePassword: (rule: any, value: string, callback: Function) => {
        if (!isEmpty(value)) {
          callback(new Error('密码不能为空'))
        } else {
          callback()
        }
      },
      validateAuthcode: (rule: any, value: string, callback: Function) => {
        if (value.length < 4) {
          callback(new Error('验证码不能少于4位数字'))
        } else {
          callback()
        }
      }
    }
    const { validateUsername, validatePassword, validateAuthcode } = staticMethods
    const state = reactive({
      authcodeimgSrc: '',
      uuid: '',
      rememberPass: false,
      loginForm: {
        username: '',
        password: '',
        authcode: ''
      },
      loginRules: {
        username: [{ validator: validateUsername, trigger: 'blur' }],
        password: [{ validator: validatePassword, trigger: 'blur' }],
        authcode: [{ validator: validateAuthcode, trigger: 'blur' }]
      },
      passwordType: 'password',
      loading: false,
      redirect: '',
      otherQuery: {}
    })

    const methods = reactive({
      showPwd: () => {
        if (state.passwordType === 'password') {
          state.passwordType = ''
        } else {
          state.passwordType = 'password'
        }
        nextTick(() => {
          (passwordRef.value as any).focus()
        })
      },
      getCaptcha: async() => {
        await verifCodeRequest({}).then((res) => {
          if (res?.code === 200) {
            state.uuid = res.data.uuid
            state.authcodeimgSrc = res.data.url
          }
        }, (error) => {
          ElMessage.error(error)
        })
      },
      handleLogin: () => {
        (loginFormRef.value as any).validate(async(valid: boolean) => {
          if (valid) {
            state.loading = true
            const { username, password, authcode } = state.loginForm
            if (state.rememberPass) {
              storage.rcSetItem(StorageType.local, 'user', {
                username,
                password
              })
            }
            await store.dispatch(UserActionTypes.ACTION_LOGIN, {
              agent_name: username,
              password,
              captcha: authcode,
              captcha_uuid: state.uuid
            })
            router
              .push({
                path: state.redirect || '/',
                query: state.otherQuery
              })
              .catch(err => {
                console.warn(err)
              })
            state.loading = false
          } else {
            return false
          }
        })
      }
    })

    function getOtherQuery(query: LocationQuery) {
      return Object.keys(query).reduce((acc, cur) => {
        if (cur !== 'redirect') {
          acc[cur] = query[cur]
        }
        return acc
      }, {} as LocationQuery)
    }

    watch(() => route.query, query => {
      if (query) {
        state.redirect = query.redirect?.toString() ?? ''
        state.otherQuery = getOtherQuery(query)
      }
    })

    onMounted(() => {
      methods.getCaptcha()
      if (state.loginForm.username === '') {
        (userNameRef.value as any).focus()
      } else if (state.loginForm.password === '') {
        (passwordRef.value as any).focus()
      }
      // 获取本地记住密码
      const localUsermess = storage.rcGetItem(StorageType.local, 'user')
      if (localUsermess) {
        const usermess = JSON.parse(localUsermess)
        state.loginForm.username = usermess.username
        state.loginForm.password = usermess.password
      }
    })

    return {
      userNameRef,
      passwordRef,
      loginFormRef,
      ...toRefs(state),
      ...toRefs(methods),
      t
    }
  }
})
</script>

<style lang="scss">
// References: https://www.zhangxinxu.com/wordpress/2018/01/css-caret-color-first-line/
@supports (-webkit-mask: none) and (not (cater-color: $loginCursorColor)) {
  // .login-container .el-input {
  //   input {
  //     color: $loginCursorColor;
  //   }
  //   input::first-line {
  //     color: $lightGray;
  //   }
  // }
}

.login-container {
  .el-input {
    display: inline-block;
    height: 47px;
    width: 85%;

    input {
      height: 47px;
      background: transparent;
      border: 0px;
      border-radius: 0px;
      padding: 12px 5px 12px 15px;
      color: #333;
      caret-color: #333;
      -webkit-appearance: none;
      opacity: 1;
      &:-webkit-autofill {
        box-shadow: 0 0 0px 1000px #fff inset !important;
        -webkit-text-fill-color: #333 !important;
      }
      &::-webkit-input-placeholder {
        color: #333;
      }
    }
  }

  .el-form-item {
    border: 1px solid rgba(255, 255, 255, 0.1);
    background:#fff;
    border-radius: 5px;
    color: #333;
  }
}
</style>

<style lang="scss" scoped>
.login-container {
  height: 100%;
  width: 100%;
  overflow: hidden;
  // background-color: $loginBg;
  .login-bg {
    position: absolute;
    /* Vertical and Horizontal center*/
    top: 0; left: 0; right: 0; bottom: 0;
    width:100%;
    height:100%;
    object-fit: cover;
    z-index: -1;
  }
  .login-form {
    position: relative;
    width: 520px;
    max-width: 100%;
    padding: 160px 35px 0;
    margin: 0 auto;
    overflow: hidden;
  }

  .tips {
    font-size: 14px;
    color: #fff;
    margin-bottom: 10px;

    span {
      &:first-of-type {
        margin-right: 16px;
      }
    }
  }

  .svg-container {
    padding: 6px 5px 6px 15px;
    color: $darkGray;
    vertical-align: middle;
    width: 30px;
    display: inline-block;
  }

  .title-container {
    position: relative;

    .title {
      font-size: 26px;
      color: $lightGray;
      margin: 0px auto 40px auto;
      text-align: center;
      font-weight: bold;
    }

    .set-language {
      color: #fff;
      position: absolute;
      top: 3px;
      font-size: 18px;
      right: 0px;
      cursor: pointer;
    }
  }

  .show-pwd {
    position: absolute;
    right: 10px;
    top: 7px;
    font-size: 16px;
    color: $darkGray;
    cursor: pointer;
    user-select: none;
  }

  .authcodeInput {
    width: calc( 100% - 160px);
  }

  .authcodeimg {
    background: #fff;
    display: inline-block;
    vertical-align: middle;
    font-size: 0;
    height: 47px;
  }
  .authcodeimg img {
    height: 47px;
  }
  .login-error{
    color: #F56C6C;
    font-size: 12px;
    line-height: 1;
  }
  ::v-deep(.remember-pass){
    color: #fff;
  }
}
</style>
