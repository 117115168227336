<template>
  <div class="changepassword-container fami-container">
    <el-form
      :model="ruleForm"
      status-icon
      :rules="rules"
      ref="ruleForm"
      label-width="100px"
      class="changepass-form"
    >
      <el-form-item
        label="旧密码"
        prop="oldPass"
      >
        <el-input
          type="password"
          v-model="ruleForm.oldPass"
          autocomplete="off"
        />
      </el-form-item>
      <el-form-item
        label="填写新密码"
        prop="pass"
      >
        <el-input
          type="password"
          v-model="ruleForm.pass"
          autocomplete="off"
        />
      </el-form-item>
      <el-form-item
        label="确认新密码"
        prop="checkPass"
      >
        <el-input
          type="password"
          v-model="ruleForm.checkPass"
          autocomplete="off"
        />
      </el-form-item>
      <el-form-item>
        <el-button
          type="primary"
          @click="submitForm('ruleForm')"
        >
          提交
        </el-button>
        <el-button @click="resetForm('ruleForm')">
          重置
        </el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import { changePassword } from '@/apis/user'
import { UserActionTypes } from '@/store/modules/user/action-types'
import { isVaildPass } from '@/utils/validate'
export default {
  data() {
    const validatePass = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入密码'))
      } else {
        if (this.ruleForm.checkPass !== '') {
          this.$refs.ruleForm.validateField('pass')
        }
        callback()
      }
    }
    const validatePass1 = (rule, value, callback) => {
      if (!isVaildPass(value)) {
        callback(new Error('密码需要英文+数字，不低于8位'))
      } else {
        if (this.ruleForm.checkPass !== '') {
          this.$refs.ruleForm.validateField('checkPass')
        }
        callback()
      }
    }
    const validatePass2 = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请再次输入密码'))
      } else if (value !== this.ruleForm.pass) {
        callback(new Error('两次输入密码不一致!'))
      } else {
        callback()
      }
    }
    return {
      ruleForm: {
        oldPass: '',
        pass: '',
        checkPass: ''
      },
      rules: {
        oldPass: [
          { validator: validatePass, trigger: 'blur' }
        ],
        pass: [
          { validator: validatePass1, trigger: 'blur' }
        ],
        checkPass: [
          { validator: validatePass2, trigger: 'blur' }
        ]
      }
    }
  },
  methods: {
    submitForm(formName) {
      this.$refs[formName].validate(async(valid) => {
        if (valid) {
          console.log(this.ruleForm.oldPass)
          const { oldPass, pass, checkPass } = this.ruleForm
          await changePassword({
            old_password: oldPass,
            password: pass,
            re_password: checkPass
          }).then((res) => {
            if (res?.code === 200) {
              this.$message({
                type: 'success',
                onClose: () => {
                  this.$store.dispatch(UserActionTypes.ACTION_LOGIN_OUT)
                  this.$router.push('/login?redirect=/').catch(err => {
                    console.warn(err)
                  })
                }
              })
              this.$message.success('修改密码成功')
            } else {
              this.$message.error(res?.msg)
            }
          })
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    resetForm(formName) {
      this.$refs[formName].resetFields()
    }
  }
}
</script>

<style lang="scss" scoped>
.changepassword-container{
  .changepass-form{
    max-width: 480px;
    ::v-deep(.el-button:hover, .el-button:focus) {
      color: #4b9d82;
      border-color: #c9e2da;
      background-color: #edf5f3;
    }
  }
}
@media (max-width: 575px){
  .changepassword-container{
    padding-left: 10px;
  }
}
</style>
